import React from 'react';
import { ButtonBase, Cookies } from '@web-passion/uikit';

import Link from '../Links';

export default function CookiesMessage() {
  const title = 'Privacy Policy';
  const url = '/privacy-policy/';
  return (
    <Cookies
      background
      position="bottom"
      offset="30px"
      button={
        <ButtonBase className="button" label="Dismiss cookies notice" secondary>
          Dismiss
        </ButtonBase>
      }
    >
      <strong
        style={{
          fontWeight: 800,
          color: `#fff`,
          marginBottom: `7px`,
          lineHeight: 1.8,
          fontSize: `1.25rem`,
          fontFamily: `'Source Sans Pro', sans-serif`,
        }}
      >
        It is necessary to use/accept cookies on this site to process your
        application.
      </strong>
      <p style={textStyle}>
        NOTE, we do not use tracking or advertising cookies or provide your
        details to 3rd parties. For more information please refer to our {` `}
        <Link
          to={url}
          content={title}
          label={`View our ${title}`}
          style={{
            ...textStyle,
            borderBottom: `1px dotted rgba(255,255,255,0.6)`,
            paddingBottom: `0.1875rem`,
          }}
        />
        .
      </p>
    </Cookies>
  );
}

const textColour = {
  color: `#fff`,
};

const textStyle = {
  ...textColour,
  fontSize: `0.875rem`,
};
